/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
import * as InternalFormatters from './formatters-internal.js'

export function image(simpleOrComplexImage = {}, desiredSize = '600x', atLeastAsLarge = true) {
  return InternalFormatters.image(simpleOrComplexImage, desiredSize, atLeastAsLarge);
}
